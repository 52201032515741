<template>
  <div id="upload-key">

    <PageHeader heading="Upload Key" />

    <b-row class="mt-2">
      <b-col cols="6">
        <b-form @submit.prevent="handleSubmit">
          <UploadTypeSwitch></UploadTypeSwitch>

          <b-form-group v-if="uploadType == 'paste'"
            id="pkey-upload"
            label="Paste your private key here:"
            label-for="pkey"
            description="Ensure key is in PEM format"
          >
            <b-form-textarea
              id="pkey"
              v-model="pkey"
              rows="10"
              required
            >
            </b-form-textarea>
          </b-form-group>

          <b-form-group v-if="uploadType == 'upload'">
            <b-form-file
              v-model="pkeyFile"
              placeholder="Choose or drop your key file here"
              drop-placeholder="Drop key file here..."
              description="Ensure key is in PEM format"
              required
            ></b-form-file>
          </b-form-group>

          <b-form-group
            id="keyname-field"
            label="Key name:"
            label-for="keyname"
          >
            <b-form-input
              id="keyname"
              v-model="keyname"
              required
            ></b-form-input>
          </b-form-group>

          <b-button type="submit" variant="warning" v-bind:disabled="submitDisabled">Upload</b-button>
        </b-form>
      </b-col>
    </b-row>

    <b-row class="mt-2" v-if="resp">
      <b-col>
        <p>
          <strong>Upload status:</strong> New ID: {{ resp.data.id }}.
          <router-link :to="{name: 'private-keys'}">Back to key list.</router-link>
        </p>
      </b-col>
    </b-row>

    <b-row class="mt-2" v-if="errors.length">
      <b-col>
        <p>
          <strong>Please correct the following errors:</strong>
          <ul>
            <li v-for="error in errors" :key="error">{{ error }}</li>
          </ul>
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader'
import PrivateKeyApi from '@/services/api/PrivateKeys'
import UploadTypeSwitch from '@/components/UploadTypeSwitch'

export default {
  name: 'upload-key',
  components: {
    PageHeader,
    UploadTypeSwitch
  },
  data() {
    return {
      pkey: '',
      keyname: '',
      resp: null,
      errors: [],
      pkeyFile: null
    }
  },
  computed: {
    submitDisabled: function() {
      if (this.uploadType == 'paste') {
        return !(this.pkey && this.keyname)
      } else if (this.uploadType == 'upload') {
        return !(this.pkeyFile && this.keyname)
      } else {
        return true
      }
    },
    uploadType() {
      return this.$store.state.uploadType
    }
  },
  methods: {
    validateForm: function () {
      this.errors = []
      // Just doing browser validation for now, which will have already happened at this point.
      return true
    },
    handleSubmit: async function() {
      if (this.validateForm()) {

        let keyContents = null
        if (this.uploadType == 'paste') {
          keyContents = this.pkey
        } else if (this.uploadType == 'upload') {
          keyContents = await this.pkeyFile.text()
          keyContents = keyContents.replace(/(\r\n|\n|\r)/gm, '\n')
        }

        const resp = await PrivateKeyApi.addKey(keyContents, this.keyname)
        if (resp.errors) {
          this.errors = this.resp.errors
        } else {
          this.resp = resp
        }
      }
    }
  }
}
</script>

<style scoped></style>
